import Vue from 'vue'
import VueRouter from 'vue-router'
import bilan from '../components/bilan.vue'
import ajoutExercice from '../components/ajoutExercice.vue'
import Display from '../components/Display.vue'
import recapExercices from '../components/recapExercices.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/accueil',
    component : Display,
    name: 'accueil',
    children : [
      {
        path: '',
        redirect: '/accueil',
        component: bilan // default child path
      },
      {
        path: '/bilan',
        name: 'bilano',
        component: bilan,
      },
      {
        path: '/ajoutExercice',
        name: 'AjoutExercice',
        component: ajoutExercice,
      },
      {
        path: '/recapExercices',
        name: 'RecapExercices',
        component: recapExercices,
      }
    ]
  },
  {
    path:'/',
    component: bilan,
    name: "bilan"
  }
]

const router = new VueRouter({
  routes
})

export default router
