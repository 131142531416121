<template>
  <div id="app">
    <navbar/>
    <router-view>
      <Display/>
    </router-view>
  </div>
</template>

<script>
import Display from './components/Display.vue';
import axios from 'axios';
import $API_URL from './global/constants.js';
import navbar from './components/navbar.vue';

export default {
  name: 'App',
  components: {
    Display,
    navbar
  }
}
</script>

<style>
#app {
  font-family: Verdana !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;  
}
</style>
