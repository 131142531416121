<template>
	<div class="navbarContainer">
		<img @click="home" src="../assets/logo.png">
		<img @click="openDetail" class="menu" src="../assets/calendrier.png">
	</div>
</template>

<script>

export default {
	name: 'navbar',
	data: function() {
		return {

		}
	},
	methods: {
		openDetail(){
			this.$router.push('/recapExercices')
		},
		home(){
			this.$router.push('/')
		}
	}
}

</script> 


<style scoped>

.navbarContainer {
	height: 10vh;
	background-color: #F26419;
	/* text-align:start; */
	vertical-align: middle;
	display: flex;
}

img {
	 width: 30vw;
	 height: 10vh;
	 margin-left: 0;
}

.menu{
	width: 10vw;
	height: 4vh;
	margin: auto;
	margin-right: 20px;
	display: flex;
}

</style>
