import { render, staticRenderFns } from "./recapExercices.vue?vue&type=template&id=667ace7c&scoped=true&"
import script from "./recapExercices.vue?vue&type=script&lang=js&"
export * from "./recapExercices.vue?vue&type=script&lang=js&"
import style0 from "./recapExercices.vue?vue&type=style&index=0&id=667ace7c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "667ace7c",
  null
  
)

export default component.exports