<template>
	<div>
		<router-view default="/"/>
	</div>
</template>
<script>
export default {
	name: "Display"
}

</script>
<style>

</style>